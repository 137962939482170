/* eslint-disable @typescript-eslint/ban-types */
import {
  UseQueryOptions,
  QueryObserverOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQuery,
} from 'react-query'
import {
  GetManagementOrgRequest,
  ListManagementOrgsRequest,
  ListManagementOrgsResponse,
  ManagementOrg,
} from '@cloudnatix-types/dashboard'
import {
  CreateOrgRequest,
  ListOrgsResponse,
  Org,
  UpdateOrgRequest as UpdateOrgRequestApi,
} from '@cloudnatix-types/orgcontroller'
import { deleteData, fetchData, mutateData, useRequestParams } from 'src/api'
import { queryClient } from 'src/api/queryClient'

const mngmtUrl = '/v1/management/orgs'
const url = '/v1/orgs'

const invalidateOrgs = () => {
  queryClient.invalidateQueries([mngmtUrl])
  queryClient.invalidateQueries([url])
}

export const useManagementOrg = (
  request: GetManagementOrgRequest,
  options?: UseQueryOptions<ManagementOrg, any>,
) => {
  const params = useRequestParams({ request, addOrgFilter: false })

  return useQuery<ManagementOrg, any>(
    [mngmtUrl, request.uuid],
    () =>
      fetchData<ManagementOrg>(`${mngmtUrl}/${request.uuid}`, { ...params }),
    {
      staleTime: 60 * 60 * 1000,
      ...options,
    },
  )
}

export const useManagementOrgs = (
  request?: ListManagementOrgsRequest,
  options?: UseInfiniteQueryOptions<ListManagementOrgsResponse, any>,
) => {
  const params = useRequestParams({
    request: request || {},
    addOrgFilter: true,
  })

  return useInfiniteQuery<ListManagementOrgsResponse, any>(
    [mngmtUrl, request],
    ({ pageParam }) => {
      return fetchData<ListManagementOrgsResponse>(mngmtUrl, {
        ...params,
        pageToken: pageParam,
      })
    },
    {
      getNextPageParam: lastPage => lastPage.nextPageToken,
      staleTime: 60 * 60 * 1000,
      ...options,
    },
  )
}

export const useOrgs = (
  options?: QueryObserverOptions<ListOrgsResponse, Error>,
) => {
  return useQuery<ListOrgsResponse, Error>(
    [url],
    () => fetchData<ListOrgsResponse>(url),
    {
      staleTime: 60 * 60 * 1000,
      // Retry for 2 minutes
      retry: 28,
      retryDelay: attempt =>
        Math.min(attempt > 1 ? attempt * 1000 : 1000, 5 * 1000),
      ...options,
    },
  )
}

export interface UpdateOrgRequest extends UpdateOrgRequestApi {
  uuid: string
}

export const useUpdateOrg = (orgId: string) =>
  useMutation(
    (data: UpdateOrgRequest) =>
      mutateData<Org, typeof data>(`${url}/${orgId}`, data, 'put'),
    { onSuccess: invalidateOrgs },
  )

export const useCreateOrg = () =>
  useMutation(
    (data: CreateOrgRequest) => mutateData<Org, typeof data>(url, data, 'post'),
    { onSuccess: invalidateOrgs },
  )

export const useDeleteOrg = () =>
  useMutation((orgId: string) => deleteData<{}>(`${url}/${orgId}`), {
    onSuccess: invalidateOrgs,
  })
